import { ValidateFn } from "codelyzer/walkerFactory/walkerFn";
import { Component, OnInit, SimpleChanges, TemplateRef } from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { CreateService } from "./create.service";
import { LoginService } from "../../login/login.service";
import { RegistrationService } from "../registration.service";
import { Router, ActivatedRoute, Params, RouterLink } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { FormUtil } from "../../utils/formutils";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { HotToastService } from "@ngneat/hot-toast";
import { TaggingService } from "src/app/dashboard/tagging.service";
import {
  SharedDataService,
  SubscriptionType,
} from "src/app/dashboard/shared-data.service";
import { NgIf, NgClass } from "@angular/common";
declare let fbq: Function;

// declare var gtag: Function;

/**
 * Create new Pratice/Account component.
 */
@Component({
  selector: "app-create-main",
  templateUrl: "./create.component.html",
  styleUrls: ["../registration.component.scss"],
  imports: [NgIf, FormsModule, NgClass, RouterLink, ReactiveFormsModule],
})
export class CreateComponent implements OnInit {
  public step: number;
  public email: string;
  public user_type: number;
  public access_level: number;
  public company_id: number;
  public company_name: string;
  public company_handle: string;
  public invited: boolean;
  public invite_id: any = null;
  public infoForm: UntypedFormGroup;
  public companyForm: UntypedFormGroup;
  public formUtil: FormUtil;
  public show_loading = false;
  public infoFormSubmitAttempt: boolean;
  public companyFormSubmitAttempt: boolean;
  loadingNameAndPassword = false;

  public modalRef: BsModalRef;

  // from drawer
  public teamSize = "";
  accountingCompanyName = "";
  ownerFirstName = "";
  ownerLastName = "";
  ownerEmail = "";
  ownerPhone = "";
  password = "";
  confirmpassword = "";

  lastValidatedEmail = "";
  validEmail = true;
  emailErrorMessage = "";

  ARR_FIRM_SIZE = ["1-2", "3-5", "6-10", "11-25", "26+"];
  // from drawer
  formStep = 1;

  isFree = false;

  constructor(
    public localStorage: LocalStorageService,
    public createService: CreateService,
    public loginService: LoginService,
    public registrationService: RegistrationService,
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public modalService: BsModalService,
    private gtmService: GoogleTagManagerService,
    private toastr: HotToastService,
    private taggingService: TaggingService,
    private sharedDataService: SharedDataService,
  ) {
    this.formUtil = new FormUtil();

    this.infoForm = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      password: [
        null,
        [
          Validators.required,
          this.formUtil.validatePassword,
          Validators.minLength(8),
        ],
      ],
    });

    this.companyForm = this.formBuilder.group({
      company_name: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    // Focus on the first input field
    if (document.getElementById("email") != null) {
      document.getElementById("email").focus();
    }

    if (
      this.localStorage.get("data") != null &&
      this.localStorage.get("user") != null
    ) {
      console.log("User already created");
      if (this.localStorage.get("data")["company"] == null) {
        this.step = 3;
      } else if (
        this.localStorage.get("user") == null ||
        this.localStorage.get("user") === undefined
      ) {
        this.router.navigate(["/registration/join"]);
      } else {
        if (this.localStorage.get("data")["user_type"] == 1) {
          this.router.navigate(["/practice/workspaces"]);
        } else {
          this.router.navigate(["/client"]);
        }
      }
    } else {
      console.log("Creating a new user: ", this.registrationService.email);
      this.email = this.registrationService.email;
      this.localStorage.set("temp_email", this.email);

      if (
        this.registrationService.invite_info != null &&
        this.registrationService.invite_info !== undefined
      ) {
        console.log("User was invited");
        this.invited = true;
        this.invite_id = this.registrationService.invite_info.id;
        // Accountant or Client
        this.user_type = this.registrationService.invite_info.type;

        if (this.registrationService.invite_info.invited_to !== null) {
          this.company_id = this.registrationService.invite_info.invited_to.id;
        } else {
          this.company_id = null;
        }

        // If company_id is null, then the user will be the first one, and will be the company admin
        this.access_level = this.company_id == null ? 1 : 2;
      } else {
        this.user_type = 1;
        this.access_level = 1;
        this.invited = false;
      }

      this.step = 2;
    }
    this.infoFormSubmitAttempt = false;
  }

  // from drawer

  validateEmail(email: string) {
    const validRegex = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    // true = disable the button, so we check the opposite
    return (
      (this.lastValidatedEmail == this.ownerEmail && !this.validEmail) ||
      !validRegex
    );
  }

  validateForm() {
    return (
      this.ownerFirstName == "" ||
      this.ownerLastName == "" ||
      this.validateEmail(this.ownerEmail) ||
      this.validatePassword()
    );
  }

  hasCapitalLetter() {
    return String(this.password).match(/([A-Z])/);
  }

  hasLowerCaseLetter() {
    return String(this.password).match(/([a-z])/);
  }

  hasSymbols() {
    return String(this.password).match(/[-!@$%#^&*()_+|~=`{}\[\]:";'<>?,.\/]/);
  }

  hasNumbers() {
    return String(this.password).match(/([0-9])/);
  }

  hasEightChars() {
    return this.password.length >= 8;
  }

  isConfirmPasswordSame() {
    return this.password && this.password == this.confirmpassword;
  }

  validatePassword() {
    return !(
      this.hasCapitalLetter() &&
      this.hasLowerCaseLetter() &&
      this.hasSymbols() &&
      this.hasNumbers() &&
      this.hasEightChars() &&
      this.isConfirmPasswordSame()
    );
  }

  nextStep() {
    this.show_loading = true;
    if (
      this.ownerFirstName == "" ||
      this.ownerLastName == "" ||
      this.ownerEmail == "" ||
      this.password != this.confirmpassword
    ) {
      return;
    }
    this.lastValidatedEmail = this.ownerEmail;
    this.registrationService.check_invitation(this.ownerEmail).then(
      (res: any) => {
        this.formStep = 2;
        this.show_loading = false;
      },
      (err: any) => {
        this.emailErrorMessage = err.error.message;
        this.validEmail = false;
        this.toastr.error(err.error.message);
        this.show_loading = false;
      },
    );
  }

  validateStepTwo() {
    return (
      this.accountingCompanyName == "" ||
      this.teamSize == "" ||
      this.ownerPhone == ""
    );
  }

  async continueToTrial() {
    if (
      this.teamSize == "" &&
      (this.accountingCompanyName == "" || !this.validateForm())
    ) {
      return;
    }

    this.show_loading = true;

    let addUserResponse = await this.createService.add_user(
      this.ownerFirstName,
      this.ownerLastName,
      this.ownerEmail,
      this.password,
      1,
      1,
      null,
      null,
    );

    this.taggingService
      .tagAnonymousUser(
        this.ownerEmail,
        this.ownerFirstName,
        this.ownerLastName,
        this.ARR_FIRM_SIZE[this.teamSize],
        "trial_flow",
        this.ownerPhone,
      )
      .subscribe(() => {});

    // gtag("event", "Trial_Started");
    this.toastr.success("User created!");
    this.localStorage.remove("temp_email");
    this.localStorage.remove("temp_first_name");
    this.localStorage.remove("temp_last_name");
    this.localStorage.remove("access_token");
    this.localStorage.remove("refresh_token");
    this.localStorage.remove("data");
    this.localStorage.remove("user");
    this.localStorage.remove("company");
    this.localStorage.remove("pin_expires");

    this.localStorage.set("data", addUserResponse.data);
    this.localStorage.set("user", addUserResponse.user);
    this.localStorage.set("remember", true);

    let loginResponse = await this.loginService.login(
      this.ownerEmail,
      this.password,
    );

    console.log("Login Response", loginResponse);
    this.localStorage.set("access_token", loginResponse.access);
    this.localStorage.set("refresh_token", loginResponse.refresh);
    this.localStorage.set("company", loginResponse.company);
    this.localStorage.set("subscription_type", loginResponse.subscription_type);

    let addCompanyResponse = await this.createService.add_company(
      this.accountingCompanyName,
      null,
      true,
      null,
      SubscriptionType.PREMIUM,
    );

    console.log("Company Response", addCompanyResponse);
    this.toastr.success("Company created!");
    this.localStorage.set("data", addCompanyResponse.data);
    this.localStorage.set("company", addCompanyResponse.company);
    this.localStorage.set("remember", true);
    this.localStorage.set(
      "subscription_type",
      addCompanyResponse.subscription_type,
    );

    this.sharedDataService.subscriptionType =
      addCompanyResponse.subscription_type;
    this.sharedDataService.data = addCompanyResponse.data;
    this.sharedDataService.company = addCompanyResponse.company;
    this.sharedDataService.user = addUserResponse.user;

    if (this.sharedDataService.subscriptionType == SubscriptionType.FREE) {
      await this.loginService.loginToFirestore(false, false);
      this.localStorage.set("fromSignUp", true);
      this.router.navigate(["/practice/getting-started"]);
      return;
    }

    await this.loginService.loginToFirestore(true, true);
  }

  // from drawer

  // invited flow
  step_name_and_password(): void {
    this.infoFormSubmitAttempt = true;
    if (this.infoForm.valid) {
      this.loadingNameAndPassword = true;
      // add more info to localStorage in case user goes back
      this.localStorage.set(
        "temp_first_name",
        this.infoForm.get("first_name").value,
      );
      this.localStorage.set(
        "temp_last_name",
        this.infoForm.get("last_name").value,
      );

      // creates the user account
      this.createService
        .add_user(
          this.infoForm.get("first_name").value,
          this.infoForm.get("last_name").value,
          this.email,
          this.infoForm.get("password").value,
          this.user_type,
          this.access_level,
          this.company_id,
          this.invite_id,
        )
        .then((res: any) => {
          const response = res;

          // removes temporary info from localStorage
          this.localStorage.remove("temp_email");
          this.localStorage.remove("temp_first_name");
          this.localStorage.remove("temp_last_name");
          this.localStorage.remove("access_token");
          this.localStorage.remove("data");
          this.localStorage.remove("user");
          this.localStorage.remove("company");
          this.localStorage.remove("pin_expires");

          this.localStorage.set("data", response.data);
          this.localStorage.set("user", response.user);
          this.localStorage.set("remember", true);

          this.loginService
            .login(this.email, this.infoForm.get("password").value)
            .then(async (loginRes: any) => {
              const loginResponse = loginRes;
              this.localStorage.set("access_token", loginResponse.access);
              this.localStorage.set("refresh_token", loginResponse.refresh);
              this.localStorage.set("company", loginResponse.company);

              this.localStorage.set(
                "subscription_type",
                loginResponse.subscription_type,
              );
              this.sharedDataService.data = response.data;
              this.sharedDataService.company = loginResponse.company;
              this.sharedDataService.user = response.user;
              this.sharedDataService.subscriptionType =
                loginResponse.subscription_type;

              this.loadingNameAndPassword = false;

              if (this.invited && this.company_id !== null) {
                console.log("invited and company_id is not null");
                // Do something here to tell the user that his account is complete
                if (this.sharedDataService.data["user_type"] == 1) {
                  console.log("Navigating to workspaces in registration");
                  this.localStorage.set("fromSignIn", true);
                  this.router.navigate(["/practice/workspaces"]);
                } else {
                  console.log("Navigating to client workspace in registration");
                  await this.loginService.loginToFirestore(false, false);
                  this.router.navigate(["/client"]);
                }
              } else {
                this.step = 3;
              }
            });
        });
    } else {
      this.loadingNameAndPassword = false;
      this.formUtil.validateAllFormFields(this.infoForm);
    }
  }
}
