import { ConversationsService } from "./conversations/conversations.service";
import { AccountantService } from "./main/accountant/accountant.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { LocalStorageService } from "src/app/utils/localstorage.service";
@Injectable({ providedIn: "root" })
export class DashboardStore {
  clientsLoaded = new Subject<any>();
  _selectTrackedItem = new Subject<any>();
  _selectJob = new Subject<any>();
  selectMessage$ = new BehaviorSubject<any>(null);
  currentCompanyChanged$ = new Subject<any>();
  user: any;

  currentCompany: any;
  selectedTrackedItem = null;
  clients = [];
  private _all_clients = new BehaviorSubject<any[]>([]);
  all_clients$ = this._all_clients.asObservable();
  activeConversationUUID = null;
  tags = [];

  constructor(
    private accountantService: AccountantService,
    private conversationsService: ConversationsService,
    private localStorage: LocalStorageService,
  ) {
    this.getClients();

    this.accountantService.getAccountingCompanyTags().then((res) => {
      this.tags = res;
    });

    this.user = this.localStorage.get("user");
  }

  get all_clients() {
    return this._all_clients.value;
  }

  set all_clients(value) {
    this._all_clients.next(value);
  }

  async getConversationCompany(companyId: number) {
    return this.clients.find((o: any) => o.id === companyId);
  }

  selectTrackedItem(tracked_item, company) {
    this.selectedTrackedItem = tracked_item;

    this._selectTrackedItem.next({
      tracked_item: tracked_item,
      company: company,
    });
  }

  selectJob(job: any) {
    this._selectJob.next(job);
  }

  unreadConversationsCount() {
    return this.clients.filter((c) => {
      if (c["company_conversation"] != null) {
        return c["company_conversation"].read == false;
      }

      return false;
    }).length;
  }

  notificationsCount() {
    return this.clients.filter((c) => {
      if (c["company_conversation"] == null) {
        return false;
      }
      return c["company_conversation"].has_notifications == true;
    }).length;
  }

  addClient(client) {
    this.clients.push(client);
  }

  changeConversationUUID(uuid) {
    this.activeConversationUUID = uuid;
  }

  updateReadStatusBadgeOnStore() {
    this.conversationsService
      .get_conversations_read_status()
      .then((res: any) => {
        let read_status = res.conversations;

        for (let x = 0; x < read_status.length; x++) {
          for (let y = 0; y < this.clients.length; y++) {
            if (
              read_status[x].conversation_id ==
              this.clients[y]["company_conversation"].id
            ) {
              this.clients[y]["company_conversation"].read =
                read_status[x].read;
            }
          }
        }
      });
  }

  setReadStatus(company_conversation_id, status) {
    for (let x = 0; x < this.clients.length; x++) {
      let last_message =
        this.clients[x]["company_conversation"]["messages"][
          this.clients[x]["company_conversation"]["messages"].length - 1
        ];

      if (
        this.clients[x]["company_conversation"].id == company_conversation_id &&
        last_message != null
      ) {
        if (last_message.sent_by.id != this.user.id) {
          this.clients[x]["company_conversation"].read = status;

          break;
        }
      }
    }
  }

  setNotificationStatus(company_conversation_id, status) {
    for (let x = 0; x < this.clients.length; x++) {
      if (
        this.clients[x]["company_conversation"].id == company_conversation_id
      ) {
        this.clients[x]["company_conversation"].has_notifications = false;
      }
    }
  }

  markAllClientNotificationsAsRead() {
    for (let x = 0; x < this.clients.length; x++) {
      this.clients[x]["company_conversation"].has_notifications = false;
    }
  }

  updateTrackedItem(company_conversation_id, tracked_item) {
    for (let x = 0; x < this.clients.length; x++) {
      if (
        this.clients[x]["company_conversation"].id == company_conversation_id
      ) {
        for (
          let y = 0;
          y < this.clients[x]["company_conversation"].tracked_items.length;
          y++
        ) {
          if (
            this.clients[x]["company_conversation"].tracked_items[y].id ==
            tracked_item.id
          ) {
            this.clients[x]["company_conversation"].tracked_items[y] =
              tracked_item;
          }
        }
      }
    }
  }

  updateConversationMessages(company_conversation_uuid, messages) {
    for (let x = 0; x < this.clients.length; x++) {
      if (
        this.clients[x]["company_conversation"].uuid ==
        company_conversation_uuid
      ) {
        this.clients[x]["company_conversation"]["messages"] = messages;
      }
    }
  }

  getClients() {
    this.accountantService.get_clients().then((res: any) => {
      this.clients = res;
      this.all_clients = res;

      this.updateReadStatusBadgeOnStore();
      this.clientsLoaded.next(null);
      console.log("clients", this.clients);
      this.accountantService
        .getTagsByWorkspace()
        .then((workspace_tags: any) => {
          // add tags to each workspace
          for (let x = 0; x < this.clients.length; x++) {
            this.clients[x]["tags"] = [];
            for (let y = 0; y < workspace_tags.length; y++) {
              if (
                this.clients[x].company_conversation.uuid ==
                workspace_tags[y].workspace_uuid
              ) {
                this.clients[x].tags = workspace_tags[y].tags;
              }
            }
          }

          console.log("clients after tags", this.clients);
        });
    });
  }
}
