import { Component, OnInit } from "@angular/core";
import {
  NavigationEnd,
  NavigationError,
  Router,
  RouterOutlet,
} from "@angular/router";
import { Intercom } from "@supy-io/ngx-intercom";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { SharedDataService } from "./dashboard/shared-data.service";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { filter } from "rxjs/operators";
import { map } from "rxjs/operators";

@Component({
  selector: "clienthub",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  title = "clienthub-front";

  constructor(
    public intercom: Intercom,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    const lastTab = this.localStorageService.get("last_tab");
    if (lastTab == "workspaceTab") {
      this.localStorageService.set("last_tab", "conversations");
    } else if (lastTab == "jobsTab") {
      this.localStorageService.set("last_tab", "jobs");
    } else if (lastTab == "clientDetailsTab") {
      this.localStorageService.set("last_tab", "details");
    } else if (lastTab == "filesManagerTab") {
      this.localStorageService.set("last_tab", "files");
    }

    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationError),
        map((evt) => evt as NavigationError),
      )
      .subscribe((evt: any) => {
        if (evt.error instanceof Error && evt.error.name == "ChunkLoadError") {
          console.log("navigation error", evt.error);
          // window.location.assign(evt.url);
        }
      });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      FingerprintJS.load({
        monitoring: false,
      }).then((fpPromise) => {
        fpPromise.get().then((result) => {
          console.log("Unique visitor ID", result.visitorId);
          this.localStorageService.set("client_id", result.visitorId);
          // this.client_id = result.visitorId;
        });
      });

      window.scrollTo(0, 0);
      // if( window.location.href.indexOf('/#/comparisons/') === -1 ) {
      // }

      if (window.location.href.indexOf("/#/#pricing") !== -1) {
        window.location.href = "/#/";
        setTimeout(function () {
          document.getElementById("pricing-link").click();
        }, 10);
      }
    });
  }
}
